import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import SpellRangeDash from './routes/SpellRangeDash';
import MyNavbar from "./Navbar";
import Home from './routes/Home';
import About from './routes/About';

class App extends Component {
  render() {
    return (
      <div className="App">
        <MyNavbar />
        <main id="App-main">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/spell-range" element={<SpellRangeDash />}></Route>
            </Routes>
          </BrowserRouter>
        </main>
      </div>
    );
  }
}

export default App;
