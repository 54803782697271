import { Component, ReactNode } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Triangle from "./Triangle";
import "./spellrange.css";

interface SpellRangeState {
    targetHeight: number,
    range: number,
    casterHeight: number,
    includeCasterHeight: boolean,
}

function findSpellWidth(range: number, height: number): number {
    return Math.floor(Math.sqrt(range*range - height*height));
}

export class SpellRangeDash extends Component<{}, SpellRangeState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            targetHeight: 0,
            range: 100,
            casterHeight: 0,
            includeCasterHeight: false
        }
    }
    getHorizontalDistance(): number {
        return (this.state.includeCasterHeight) ? 
            findSpellWidth(this.state.range, this.state.targetHeight - this.state.casterHeight)
            : findSpellWidth(this.state.range, this.state.targetHeight);
    }
    updateDistance(e: any) {
        if (e.target.id === "spellRange") {
            let range = parseInt(e.target.value);
            if (range !== this.state.range) {
                this.setState({...this.state, range: range})
            }
        }
        else if (e.target.id === "targetHeight") {
            let targetHeight = parseInt(e.target.value);
            if (targetHeight !== this.state.targetHeight) {
                this.setState({...this.state, targetHeight: targetHeight});
            }
        }
        if (e.target.id === "includeCasterHeight") {
            let shouldInclude = e.target.checked;
            this.setState({...this.state, includeCasterHeight: shouldInclude});
        }
        if (e.target.id === "casterHeight") {
            let casterHeight = parseInt(e.target.value);
            if (casterHeight !== this.state.casterHeight) {
                this.setState({...this.state, casterHeight: casterHeight})
            }
        }
        console.log(this.state)
        console.log(e)
    }
    render(): ReactNode {
        return (
            <Container>
                <h1 className="title">Spell Range Calculator</h1>
                <Row className="justify-content-md-center">
                    <Col sm>
                        <Form onChange={(e) => this.updateDistance(e)}>
                            <Form.Group>
                                <Form.Label>Range of spell being cast:</Form.Label>
                                <Form.Control type="number" id="spellRange" defaultValue={this.state.range}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Height of the Target:</Form.Label>
                                <Form.Control type="number" id="targetHeight" defaultValue={this.state.targetHeight}/>
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Check type="checkbox" id="includeCasterHeight" label="Include Height of Caster?" defaultChecked={this.state.includeCasterHeight}/>
                                <Form.Label>Caster Height:</Form.Label>
                                <Form.Control type="number" id="casterHeight" defaultValue={this.state.casterHeight} disabled={!this.state.includeCasterHeight}/>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col className="align-middle" sm>
                        <Triangle width={this.getHorizontalDistance()} height={this.state.targetHeight}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}