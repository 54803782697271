import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

function MyNavbar() {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
            <Navbar.Brand href="/">DnD Tools</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    <Nav.Item>
                        <Nav.Link eventKey="1" href="/">
                            Home
                        </Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="Misc Tools">
                        <NavDropdown.Item href="/spell-range">Spell Range</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Item>
                        <Nav.Link eventKey="1" href="/about">
                            About
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MyNavbar;