import { Col, Container, Row } from "react-bootstrap";
import triangle from "./triangle.svg";
import "./Triangle.css";


interface TriangleProps {
    height: number,
    width: number,
}

function Triangle(props: TriangleProps) {
    let actual_height = (props.height > 100) ? props.height : 100;
    let actual_width = (props.width > 150) ? props.width : 150;
    let isNegativeHeight = props.height < 0;
    return (
        <Container>
            <Row>
                <Col>{isNegativeHeight && <span>Max Distance: {isNaN(props.width) ? 0 : props.width}</span>}</Col>
            </Row>
            <Row>
                <Col></Col>
                <Col> 
                    <img src={triangle} alt="Triangle" width={actual_width} height={actual_height} 
                        className={isNegativeHeight? "flip" : ""}
                        />
                </Col>
                <Col className="Triangle-right-metric"><span>{props.height}</span></Col>
            </Row>
            <Row>
                <Col className="align-left">{!isNegativeHeight && <span>Max Distance: {isNaN(props.width) ? 0 : props.width}</span>}</Col>
            </Row>
        </Container>
    )
}

export default Triangle;